import {
    listarSecciones,
    altaSeccion,
    deleteSeccion,
    updateSeccion,
} from '../lib/secciones-api';

const initialState = {
    secciones: [],
    guardado: 0
};

const LISTAR_SECCIONES = 'LISTAR_SECCIONES';
const ALTA_SECCION = 'ALTA_SECCION';
const DELETE_SECCION = 'DELETE_SECCION';
const UPDATE_SECCION = 'UPDATE_SECCION';

const listarSeccionesAction = (secciones) => ({ type: LISTAR_SECCIONES, payload: secciones });
const altaSeccionAction = (guardado) => ({ type: ALTA_SECCION, payload: guardado });
const deleteSeccionAction = () => ({ type: DELETE_SECCION });
const updateSeccionAction = (guardado) => ({ type: UPDATE_SECCION, payload: guardado });

export const fetchlistarSecciones = (idEmpresa) => {
    return (dispatch) => {
        listarSecciones(idEmpresa)
        .then(res => {
            if (res && res.stat == 0 && res.err == "Usuario no logueado") {
                window.localStorage.removeItem('cliente');
                window.location.href = window.location.protocol + "//" + window.location.host + '/user/login'
            } else {
                dispatch(listarSeccionesAction(res));
            }
        })
    }
};

export const fetchAltaSeccion = (idEmpresa, nombre, observacion) => {
    return async (dispatch) => {
        return altaSeccion(idEmpresa, nombre, observacion)
            .then(res => {
                return dispatch(altaSeccionAction(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};

export const fetchdeleteSeccion = (idSeccion) => {
    return async (dispatch) => {
        return deleteSeccion(idSeccion)
            .then(res => {
                return dispatch(deleteSeccionAction(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};

export const fetchUpdateSeccion = (idSeccion, idEmpresa, nombre, observacion) => {
    return async (dispatch) => {
        return updateSeccion(idSeccion, idEmpresa, nombre, observacion)
            .then(res => {
                return dispatch(updateSeccionAction(res));
            })
            .catch(res => {
                console.log(res);
            })
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        //en todos los casos regresamos un objeto nuevo en el cual incluimos todos las propiedades del objeto state con ...state
        case LISTAR_SECCIONES:
            //cambiamos el valor de la propiedad post  
            return { ...state, secciones: action.payload };
        case ALTA_SECCION:
            //cambiamos el valor de la propiedad post  
            return { ...state, guardado: action.payload };
        case UPDATE_SECCION:
            //cambiamos el valor de la propiedad post  
            return { ...state, guardado: action.payload };
        case DELETE_SECCION:
            return { ...state };
        default:
            return { ...state };
    }
}